<template>
  <div class="nav_container">
    <div class="nav navBackground">
      <div class="logo">
        <img src="@/assets/logo.png" alt="观兰" />
      </div>
      <div class="menu">
        <div
          class="menu_one"
          v-for="(item, index) in items.list"
          :key="index"
          @click="goToOne(item, index)"
        >
          <div class="subName">
            <span>{{ item.name }}</span>
          </div>
          <div class="sub" v-show="item.children.length !== 0">
            <div
              class="menu_two"
              v-for="(itemChild, k) in item.children"
              :key="k"
            >
              <span @click="goToTwo(itemChild, k)">{{ itemChild.name }}</span>
              <div class="subWra" v-show="itemChild.children.length !== 0">
                <div
                  class="menu_three"
                  v-for="(itemChildren, i) in itemChild.children.slice(0, 6)"
                  :key="i"
                >
                  <span @click="goToThree(itemChildren, i)">{{
                    itemChildren.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu_one" @click="$router.push('/condo/login')">
          <span>CONDO</span>
        </div>
      </div>
    </div>
    <div class="nav_small">
      <div class="menu_fold">
        <i
          class="icon iconfont icon-daohang"
          v-if="!isFold"
          @click="controlMenu(isFold)"
        ></i>
        <i
          class="icon iconfont icon-chahao"
          v-else
          @click="controlMenu(isFold)"
        ></i>
      </div>
      <div class="logo_small">
        <img src="@/assets/logo.png" alt="观兰" />
      </div>
      <div class="mask_small" @click="controlMenu(isFold)"></div>
      <div class="menu_small">
        <div
          class="menu_one_small"
          v-for="(item, index) in items.list"
          :key="index"
          @click="goToOne(item, index)"
        >
          <div class="subName_small">
            <span>{{ item.name }}</span>
            <span
              v-show="item.children.length !== 0"
              class="icon iconfont icon-youjiantou"
            ></span>
          </div>
          <div class="sub_small" v-show="item.children.length !== 0">
            <div
              class="menu_two_small"
              v-for="(itemChild, k) in item.children"
              :key="k"
            >
              <span>
                <span @click="goToTwo(itemChild, k)">{{ itemChild.name }}</span>
                <span
                  v-show="itemChild.children.length !== 0"
                  class="icon iconfont icon-youjiantou"
                ></span>
              </span>
              <div
                class="subWra_small"
                v-show="itemChild.children.length !== 0"
              >
                <div
                  class="menu_three_small"
                  v-for="(itemChildren, i) in itemChild.children.slice(0, 6)"
                  :key="i"
                >
                  <span @click="goToThree(itemChildren, i)">{{
                    itemChildren.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu_one_small" @click="$router.push('/condo/login')">
          <span>CONDO</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNavMenuFunction } from "@/api/nav";
import { onBeforeMount, reactive, ref } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import $ from "jquery";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const items = reactive({
      list: [],
    });

    async function fetchNavMenu() {
      const res = await getNavMenuFunction();
      var params = res.data;
      params.forEach((e, i) => {
        if(e.name == '最新产品' && e.status == false){
          params.splice(i, 1)
        }
      });
      items.list = params;
    }

    //一级导航
    function goToOne(item, index) {
      $(".menu_one")
        .eq(index)
        .addClass("active")
        .siblings()
        .removeClass("active");
      if (item.children.length == 0) {
        router.push(`${item.path}`);
      }
    }

    function goToTwo(item) {
      router.push(`${item.path + "?id=" + item._id}`);
    }

    function goToThree(item) {
      router.push(`${item.path + "?id=" + item._id}`);
    }

    //监听滚动条
    function resizeScrollFunction() {
      let nav = document.querySelector(".nav");
      let menu = document.querySelector(".menu");
      let logo = document.querySelector(".logo");
      nav.style.height = "50px";
      nav.style.top = "20px";
      nav.style.backgroundColor = "rgba(74, 67, 60, 0.5)";
      menu.style.height = "50px";
      menu.style.lineHeight = "50px";
      menu.style.fontSize = "calc(100vw / 130)";
      logo.style.transform = "scale(0.8)";
      window.addEventListener("scroll", function (e) {
        let scrollTop = e.target.scrollingElement.scrollTop;
        if (scrollTop == 0) {
          nav.classList.remove("active");
          menu.classList.remove("menuActive");
          menu.style.height = "50px";
          menu.style.lineHeight = "50px";
          logo.style.transform = "scale(0.8)";
          menu.style.fontSize = "calc(100vw / 130)";
        } else {
          nav.classList.add("active");
          menu.classList.add("menuActive");
          logo.style.transform = "scale(1)";
          menu.style.fontSize = "calc(100vw / 120)";
        }
      });
    }

    function removeScrollFunction() {
      let nav = document.querySelector(".nav");
      let menu = document.querySelector(".menu");
      let logo = document.querySelector(".logo");
      nav.removeAttribute("style");
      menu.removeAttribute("style");
      logo.removeAttribute("style");
      nav.classList.remove("active");
      menu.classList.remove("menuActive");
      $(".menu_one").each(function () {
        $(this).find(".sub").removeClass("subActive");
      });
      window.addEventListener("scroll", function () {
        menu.removeAttribute("style");
        logo.removeAttribute("style");
        nav.classList.remove("active");
        menu.classList.remove("menuActive");
      });
    }

    onBeforeRouteUpdate((to) => {
      if (to.path == "/home/page") {
        resizeScrollFunction();
      } else {
        removeScrollFunction();
      }
    });

    //控制大屏二级、三级导航的出现
    function hoverFunction() {
      let nav = document.querySelector(".nav");
      $(".menu_one").eq(0).addClass("active");
      $(".menu_one").each(function () {
        $(this).hover(
          function () {
            nav.classList.length == 2 && route.path == "/home/page"
              ? $(this).find(".sub").addClass("subActive")
              : $(this).find(".sub").addClass("subMenuActive");
          },
          function () {
            nav.classList.length == 2 && route.path == "/home/page"
              ? $(this).find(".sub").removeClass("subActive")
              : $(this).find(".sub").removeClass("subMenuActive");
          }
        );

        if ($(this).find(".sub").find(".menu_two").length !== 0) {
          $(this)
            .find(".sub")
            .find(".menu_two")
            .each(function () {
              $(this).hover(
                function () {
                  if ($(this).find(".subWra").find(".menu_three").length <= 1) {
                    $(this).find(".subWra").css("min-width", "120px");
                    $(this)
                      .find(".subWra")
                      .find(".menu_three")
                      .eq(0)
                      .css("width", "100%");
                  }
                  $(this).find(".subWra").addClass("subWraActive");
                },
                function () {
                  if ($(this).find(".subWra").find(".menu_three").length <= 1) {
                    $(this).find(".subWra").css("min-width", "");
                    $(this)
                      .find(".subWra")
                      .find(".menu_three")
                      .eq(0)
                      .css("width", "");
                  }
                  $(this).find(".subWra").removeClass("subWraActive");
                }
              );
            });
        }
      });
    }

    //小屏操作
    const isFold = ref(false);
    function navSmallFunction() {
      $(".menu_one_small").each(function () {
        var that = $(this);
        $(this)
          .find(".subName_small")
          .find(".icon-youjiantou")
          .click(function () {
            if (that.find(".sub_small").css("display") == "none") {
              $(this).css("transform", "rotate(90deg)");
              that.find(".sub_small").slideDown(300);
            } else {
              $(this).css("transform", "");
              that.find(".sub_small").slideUp(300);
            }
          });

        $(this)
          .find(".sub_small")
          .find(".menu_two_small")
          .each(function () {
            var that_two = $(this);
            $(this)
              .find(".icon-youjiantou")
              .click(function () {
                if (that_two.find(".subWra_small").css("display") == "none") {
                  $(this).css("transform", "rotate(90deg)");
                  that_two.find(".subWra_small").slideDown(300);
                } else {
                  $(this).css("transform", "");
                  that_two.find(".subWra_small").slideUp(300);
                }
              });
          });
      });
    }

    function controlMenu(val) {
      if (!val) {
        isFold.value = !val;
        $(".mask_small").show(300);
        $(".menu_small").show(300);
        $("body").css("overflow", "hidden");
      } else {
        isFold.value = !val;
        $(".mask_small").hide(300);
        $(".menu_small").hide(300);
        $("body").css("overflow", "");
      }
    }

    onBeforeMount(() => {
      let innerWidth = $(window).width();
      if (innerWidth <= 768) {
        fetchNavMenu().then(() => {
          navSmallFunction();
        });
      } else {
        fetchNavMenu().then(() => {
          if (route.path == "/home/page") {
            resizeScrollFunction();
          } else {
            removeScrollFunction();
          }
          hoverFunction();
        });
      }
    });

    return {
      items,
      isFold,
      goToOne,
      goToTwo,
      goToThree,
      controlMenu,
    };
  },
};
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0px 3%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  transition: all 0.3s;
  background-color: rgba(74, 67, 60, 1);
}

.nav.active {
  top: 0 !important;
  height: 70px !important;
  background-color: rgba(74, 67, 60, 1) !important;
}

.nav .logo {
  margin-right: 8%;
  cursor: pointer;
  position: relative;
  transform: scale(1);
  transition: all 0.3s;
}

.nav .logo img {
  width: auto;
  max-height: 50px;
}

.nav .menu {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-size: calc(100vw / 130);
}

.nav .menu.menuActive {
  height: 70px !important;
  line-height: 70px !important;
}

.nav .menu .menu_one {
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  padding: 0 30px;
}

.nav .menu .menu_one i {
  display: none;
}

.nav .menu .menu_one:hover {
  color: rgba(255, 255, 255, 1);
}

.nav .menu .menu_one.active {
  color: rgba(255, 255, 255, 1);
}

.sub {
  position: absolute;
  background-color: #2d2926;
  left: 50%;
  transform: translateX(-50%);
  /* width: 100%; */
  min-width: 100px;
  opacity: 0;
  top: 0;
  /* top: 70px;
  opacity: 1; */
  z-index: -1;
  visibility: hidden;
  transition: all 0.3s;
  padding: 0 20px;
}

.subActive {
  top: 50px;
  opacity: 1;
  z-index: 1;
  visibility: visible;
}

.subMenuActive {
  top: 70px;
  opacity: 1;
  z-index: 1;
  visibility: visible;
}

.menu_two {
  transition: all 0.3s;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 10px;
  position: relative;
}

.menu_two:first-child {
  padding-top: 10px;
}

.menu_two:last-child {
  padding-bottom: 10px;
}

.menu_two:hover {
  color: #ea8818;
}

.subWra {
  position: absolute;
  top: 0;
  background-color: rgba(45, 41, 38, 0.8);
  height: auto;
  width: auto;
  min-width: 240px;
  padding: 10px;
  left: calc(100% + 20px);
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  display: flex;
  flex-wrap: wrap;
}

.subWraActive {
  left: calc(100% + 20px);
  opacity: 1;
  z-index: 1;
}

.menu_three {
  width: 50%;
  color: rgba(255, 255, 255, 0.5);
}

.menu_three:hover {
  color: rgba(255, 255, 255, 1);
}

.nav_small {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .icon-youjiantou {
    transition: all 0.3s;
    margin-left: 10px;
  }

  .nav_small {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background-color: rgba(74, 67, 60, 1);
  }

  .menu_fold {
    color: #fff;
  }

  .menu_fold i {
    font-size: 24px;
  }

  .logo_small {
    width: 180px;
    height: 20px;
  }

  .logo_small img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .mask_small {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
  }

  .menu_small {
    position: fixed;
    left: 0;
    top: 50px;
    padding: 10px;
    width: auto;
    background-color: #000;
    color: #fff;
    height: calc(100% - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 80%;
    min-width: 50%;
    white-space: nowrap;
    display: none;
  }

  .menu_small::-webkit-scrollbar {
    display: none;
  }

  .menu_one_small {
    padding: 20px 0 0;
  }

  .subName_small {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .subName_small span:nth-child(1) {
    overflow: hidden;
  }

  .menu_two_small {
    padding: 20px 0 0;
  }

  .menu_two_small > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu_two_small > span span:nth-child(1) {
    padding-left: 10px;
    overflow: hidden;
  }

  .menu_three_small {
    padding: 20px 0 0;
  }

  .menu_three_small span {
    padding-left: 20px;
  }

  .sub_small {
    display: none;
  }

  .subWra_small {
    display: none;
  }
}
</style>