<template>
  <div class="home">
    <transition name="fade">
      <div class="loading" v-show="loading">
        <div class="big">
          <img src="@/assets/load-4.png" alt="观兰" />
        </div>
        <div class="small">
          <img src="@/assets/load-3.png" alt="观兰" />
        </div>
        <div class="logoA">
          <img class="scale" src="@/assets/load-2.png" alt="观兰" />
        </div>
      </div>
    </transition>
    <nav-menu v-show="!loading"></nav-menu>
    <router-view v-show="!loading"></router-view>
    <footer-menu v-show="!loading"></footer-menu>
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";
import FooterMenu from "@/components/Footer.vue";
import { onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
export default {
  components: {
    NavMenu,
    FooterMenu,
  },
  setup() {
    let loading = ref(true);
    var status = localStorage.loading ? localStorage.loading : "true";

    const scrollTop = (element) => {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    onBeforeRouteUpdate(() => {
      scrollTop(document.body);
    });

    onMounted(() => {
      if (status == "true") {
        let big = document.querySelector(".big");
        let small = document.querySelector(".small");
        let logo = document.querySelector(".logoA");
        big.animate(
          [
            { top: "0", opacity: "0" },
            { top: "25%", opacity: "0.5" },
            { top: "50%", opacity: "1" },
          ],
          {
            duration: 3000,
            fill: "forwards",
            iterations: 1,
          }
        );

        small.animate(
          [
            { top: "100%", opacity: "0" },
            { top: "75%", opacity: "0.5" },
            { top: "50%", opacity: "1" },
          ],
          {
            duration: 3000,
            fill: "forwards",
            iterations: 1,
          }
        );

        logo.animate([{ opacity: "0" }, { opacity: "1" }], {
          duration: 3000,
          iterations: "1",
          delay: 3000,
          fill: "forwards",
        });

        setTimeout(() => {
          loading.value = false;
          localStorage.loading = false;
        }, 6000);
      } else {
        loading.value = false;
        localStorage.loading = false;
      }
    });

    return {
      loading,
    };
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}

.loading {
  background: url("@/assets/load-1.png");
  background-size: 100% 100%;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.big {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-52%, -70%);
}

.logoA {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 100%);
  opacity: 0;
}

img {
  width: 100%;
  height: 100%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .loading {
    background-size: unset;
  }

  .big {
    width: 100%;
  }

  .logoA {
    transform: translate(-50%, 150%);
  }
}
</style>
