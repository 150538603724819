import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);
import router from './router'

import "@/common/swiper.css";
import lazyPlugin from 'vue3-lazy';

import '@/assets/iconfont/iconfont.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

app.use(router).use(ElementPlus).use(lazyPlugin, {
    loading: require('@/assets/logo.png'), // 图片加载时默认图片
    error: require('@/assets/logo.png')// 图片加载失败时默认图片
}).mount('#app')
