<template>
  <div class="homePage">
    <div class="slick">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :navigation="{ nextEl: '.next', prevEl: '.prev' }"
        :pagination="{ clickable: true }"
        :autoplay="{ delay: 2000 }"
        effect="fade"
      >
        <swiper-slide v-for="(item, i) in items.slick" :key="i">
          <img :src="item.url" alt="观兰" />
        </swiper-slide>
      </swiper>

      <div class="pre">
        <div class="prev">
          <img src="@/assets/left.png" alt="" />
        </div>
        <div class="next">
          <img src="@/assets/right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="wrapper">
        <div class="stripes">
          <span>PRODUCTS SERIES</span>
        </div>
        <div class="items grey-bg-1">
          <div class="items_box">
            <div class="items_title color-2">
              <span>{{ items.pro.name }}</span>
            </div>
            <div class="items_desc color-3">
              <span v-html="items.pro.desc"></span>
            </div>
            <div class="list">
              <div
                v-for="(item, i) in items.pro.children"
                :key="i"
                @click="goToProduct(item)"
              >
                <div class="cover">
                  <img :src="item.cover[0].url" alt="观兰" />
                </div>
                <div class="banner">
                  <span>{{ item.name }}</span>
                  <span>{{ item.en }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper space">
        <div class="stripes">
          <span>SPACE DISPLAY</span>
        </div>
        <div class="items grey-bg-1">
          <div class="items_box">
            <div class="items_title color-2">
              <span>{{ items.space.name }}</span>
            </div>
            <div class="items_desc color-3">
              <span v-html="items.space.desc"></span>
            </div>
            <div class="list">
              <div
                v-for="(item, i) in items.space.children"
                :key="i"
                @click="goToProduct(item)"
              >
                <div class="cover">
                  <img :src="item.cover[0].url" alt="观兰" />
                </div>
                <div class="banner">
                  <span>{{ item.name }}</span>
                  <span>{{ item.en }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper brand">
        <div class="stripes">
          <span>BRAND INTERPRETATION</span>
        </div>
        <div class="items grey-bg-1">
          <div class="items_box">
            <div class="items_title color-2">
              <span>{{ items.brand.name }}</span>
            </div>
            <div class="list">
              <div
                v-for="(item, i) in items.brand.cover"
                :key="i"
                @click="previewImage(item)"
              >
                <div class="cover">
                  <img :src="item.url" alt="观兰" />
                </div>
              </div>
            </div>
            <div class="btn" @click="$router.push('/brand/mean')">
              <span>More</span>
              <span>>></span>
            </div>
          </div>
        </div>
        <picture-dialog
          :isShow="loadingShow"
          :imgUrl="imgUrl"
          @close="close"
        ></picture-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { homePageDataFunction } from "@/api/homePage";
import { onBeforeMount, reactive, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import { useRouter } from "vue-router";
import PictureDialog from "@/components/PictureDialog.vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
export default {
  components: {
    Swiper,
    SwiperSlide,
    PictureDialog,
  },
  setup() {
    const router = useRouter();
    const items = reactive({
      slick: [],
      pro: {},
      space: {},
      brand: {},
    });

    async function fetchInfo() {
      const res = await homePageDataFunction();
      items.slick = res.data.slick;
      items.pro = res.data.pro;
      items.space = res.data.space;
      items.brand = res.data.brand;
    }

    function goToProduct(item) {
      item.children.length !== 0
        ? router.push(`${item.path + "?id=" + item._id}`)
        : "";
    }

    onBeforeMount(() => {
      fetchInfo();
    });

    //点击放大图片
    let loadingShow = ref(false);
    let imgUrl = ref(null);
    const previewImage = (val) => {
      loadingShow.value = true;
      imgUrl.value = val.url;
    };

    const close = () => {
      loadingShow.value = false;
      imgUrl.value = null;
    };

    return {
      items,
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      goToProduct,
      loadingShow,
      imgUrl,
      previewImage,
      close,
    };
  },
};
</script>

<style scoped>
.items {
  padding: 20px 0 80px;
  position: relative;
}

.items::before {
  content: "";
  background-color: #ded5d0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 230px;
  height: 40px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  top: 0;
}

.items_box {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.items_title {
  font-size: calc(100vw / 70);
  font-weight: bold;
}

.items_desc {
  margin: 30px 0;
}

.items_box .list {
  display: flex;
  justify-content: center;
}

.space .items_box .list > div {
  width: calc(100% / 3);
}

.items_box .list > div {
  width: 25%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: filter 0.3s;
}

.items_box .list > div .cover {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  filter: grayscale(100%);
}

.items_box .list > div:hover .cover {
  filter: none;
}

.items_box .list > div .cover img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.items_box .list > div .banner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  background-color: rgba(25, 25, 25, 0.6);
  color: #fff9e6;
  padding: 10px 0;
  transition: all 0.5s;
}

.items_box .list > div:hover .banner {
  background-color: rgba(64, 52, 38, 0.6);
}

.items_box .list > div .banner span {
  display: block;
}

.items_box .list > div:hover .cover img {
  transform: scale(1.2);
}

.items_box .list > div:hover .banner {
  top: 0;
  transform: translateY(0);
}

.brand .items_box {
  max-width: 100%;
}

.brand .items_box .list {
  margin: 30px 0;
  position: relative;
  z-index: 1;
}

.brand .items_box .list > div {
  overflow: hidden;
}

.brand .items_box .list > div:hover .cover img {
  transform: scale(1.2);
}

.brand .items_box .list > div .cover img {
  transition: all 0.3s;
}

.brand .items_box .btn {
  background-color: #df6309;
  color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -80%);
  z-index: 0;
  padding-top: 40px;
  cursor: pointer;
  transition: all 0.3s;
}

.brand .items_box .btn:hover {
  color: #df6309;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .homePage {
    padding-bottom: 50px;
  }

  .pre {
    display: none;
  }

  .items {
    padding: 20px 0;
  }

  .items::before {
    display: none;
  }

  .items_title {
    font-size: calc(100vw / 20);
  }

  .items_desc {
    margin: 20px 10px;
  }

  .items_box .list {
    flex-direction: column;
  }

  .items_box .list > div {
    width: 100%;
  }

  .space .items_box .list > div {
    width: 100%;
  }

  .brand .items_box .btn {
    position: unset;
    transform: unset;
    border-radius: 10px;
    padding-top: 0;
    height: 50px;
    flex-direction: row;
  }

  .brand .items_box .btn span:nth-child(1) {
    margin-right: 5px;
  }

  .items_box .list > div:hover .cover img {
    transform: scale(1);
  }

  .items_box .list > div:hover .banner {
    top: 50%;
    transform: translateY(-50%);
  }

  .brand .items_box .list > div:hover .cover img {
    transform: scale(1);
  }
}
</style>