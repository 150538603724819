<template>
  <div class="footer">
    <span
      >网站备案号: <span @click="move()"> 苏ICP备2021037527号-1</span
      ><span> | carenessland@1996 copyright</span></span
    >
  </div>
</template>

<script>
export default {
  setup() {
    const move = () => {
      window.open("http://beian.miit.gov.cn/");
    };

    return {
      move,
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #6d6864;
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: #bcbcbc;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.footer span {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .footer {
    z-index: 999;
  }
}
</style>