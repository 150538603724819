<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  /* 解决移动端点击出现蓝框问题 安卓 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: calc(100vw / 120);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* 解决ios上去除微信点击蓝色边框 */
a:focus,
input:focus,
p:focus,
div:focus i:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-modify: read-write-plaintext-only;
}

input {
  outline: none;
}

input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #b4b1b1;
  font-size: calc(100vw / 80);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
}

#app {
  height: 100%;
}

.general-bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.font-1 {
  font-size: calc(100vw / 100);
}

.font-2 {
  font-size: calc(100vw / 200);
}

.font-pang {
  font-family: "pang";
}

.color-1 {
  color: #000;
}

.color-2 {
  color: #483e35;
}

.color-3 {
  color: #867d78;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  display: block;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.grey-bg-1 {
  background-color: #eee7df;
}

.grey-bg-2 {
  background-color: #ddd6ce;
}

.navGap {
  padding-top: 70px;
  padding-bottom: 50px;
}

.stripes {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  overflow: hidden;
  background: linear-gradient(to right, #efe6e1 0, #423930 50%, #efe6e1 100%);
}

.stripes span {
  color: #efe6e1;
  letter-spacing: 40px;
  font-size: calc(100vw / 130);
  font-family: '微软雅黑';
}

@media screen and (max-width: 768px) {
  html, body {
    font-size: calc(100vw / 25);
  }

  .stripes span {
    letter-spacing: 10px;
  }

  .navGap {
    padding-top: 50px;
  }
}
</style>
