<template>
  <teleport to="body">
    <transition name="fade">
      <!-- <div class="wrapper" v-if="isShow">
        <div class="mask" @click="close()"></div>
        <div class="close" @click="close()">
          <img src="@/assets/cha-1.png" alt="" />
        </div>
        <div class="viewer">
          <img :src="imgUrl" alt="观兰" />
        </div>
      </div> -->
      <el-image
        :src="imgUrl"
        :preview-src-list="items.srcList"
        :initial-index="4"
        fit="cover"
      />
    </transition>
  </teleport>
</template>

<script>
import { reactive } from '@vue/reactivity';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const items = reactive({
      srcList: ['http://192.168.0.102:4122/uploads/1655903214874.png']
    })

    function close() {
      emit("close", false);
    }

    return {
      items,
      close,
    };
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
}

.close {
  top: 60px;
  right: 60px;
  width: 100px;
  height: 100px;
  font-size: 24px;
  color: #fff;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
}

.close img {
  width: 100%;
  height: 100%;
}

.viewer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewer img {
  transform: scale(1) rotate(0deg);
  margin-left: 0px;
  margin-top: 0px;
  max-height: 100%;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .close {
    top: 50px;
    right: 0px;
    width: 60px;
    height: 60px;
  }
}
</style>