import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomePage from '@/views/HomePage/HomePage.vue'
// import Upgrade from '@/views/Upgrade/Upgrade.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'upgrade',
  //   component: Upgrade,
  //   meta: { 
  //     title: "升级中"
  //   }
  // },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/home/page',
    children: [
      {
        path: '/home/page',
        name: 'HomePage',
        component: HomePage,
        meta: {
          title: "观兰精工定制"
        }
      },
      {
        path: '/latest/series',
        name: 'LatestSeries',
        component: () => import('@/views/LatestProduct/LatestSeries.vue'),
        meta: {
          title: "最新产品"
        }
      },
      {
        path: '/latest/series/detail',
        name: 'LatestSeriesDetail',
        component: () => import('@/views/LatestProduct/LatestSeriesDetail.vue'),
        meta: {
          title: "产品详情"
        }
      },
      {
        path: '/product/series',
        name: 'ProductSeries',
        component: () => import('@/views/Product/ProductSeries.vue'),
        meta: {
          title: "产品系列"
        }
      },
      {
        path: '/product/series/detail',
        name: 'ProductSeriesDetail',
        component: () => import('@/views/Product/ProductSeriesDetail.vue'),
        meta: {
          title: "产品详情"
        }
      },
      {
        path: '/space/series',
        name: 'SpaceSeries',
        component: () => import('@/views/Space/SpaceSeries.vue'),
        meta: {
          title: "空间系列"
        }
      },
      {
        path: '/space/detail',
        name: 'SpaceSeriesDetail',
        component: () => import('@/views/Space/SpaceSeriesDetail.vue'),
        meta: {
          title: "空间详情"
        }
      },
      {
        path: '/company/profile',
        name: 'CompanyProfile',
        component: () => import('@/views/AboutUs/CompanyProfile.vue'),
        meta: {
          title: "公司简介"
        }
      },
      {
        path: '/contact/us',
        name: 'AboutUs',
        component: () => import('@/views/AboutUs/ContactUs.vue'),
        meta: {
          title: "联系我们"
        }
      },
      {
        path: '/join/us',
        name: 'JoinUs',
        component: () => import('@/views/JoinUs/JoinUs.vue'),
        meta: {
          title: "加入我们"
        }
      },
      {
        path: '/recruitment/info',
        name: 'RecruitmentInfo',
        component: () => import('@/views/JoinUs/RecruitmentInfo.vue'),
        meta: {
          title: "招聘信息"
        }
      },
      {
        path: '/brand/mean',
        name: 'BrandMean',
        component: () => import('@/views/BrandMean/BrandMean.vue'),
        meta: {
          title: "品牌释义"
        }
      },
      {
        path: '/condo/login',
        name: 'CondoLogin',
        component: () => import('@/views/Condo/CondoLogin.vue'),
        meta: {
          title: "condo"
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  next();
  document.title = to.meta.title;
})

export default router
